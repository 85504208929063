.bottomnav {
  padding: 15px;
  margin-bottom: 10px;
  color: #ffffff;
  flex-wrap: wrap;
  @include media-breakpoint-up(md) {
    text-align: center;
  }
  a{
    display: block;
    line-height: 2rem;
    color: #ffffff;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
  .seperator{
    display: none;
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}

.footer-gold{
  background-color: $primary;
}

.footerlogo{
  margin-top: 50px;
  background-color: #ffffff;
  padding: 0px 10px 30px 10px;
}

.footerlogos{
  .row{
    padding: 20px 0px;
  }
  .logorow{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0px;
  }
  img.quer{
    width: 120px;
  }
  img.hoch{
    width: 80px;
  }
  @include media-breakpoint-down(md) {
    .logorow{
      flex-wrap: wrap;
      gap: 30px;
    }
    img{
      max-width: 100%;
    }
  }
}