html body .vc_message_box-icon {
  font-size: 0.8em !important ;
  font-style: normal;
  font-weight: 400;
  width: 5.25em !important ;
}

html body {
  .hintergundblau{
    background-color: #0089c1 !important;
    a{
      color: #ffffff !important;
    }
  }

  .hintergundhellblau{
    background-color: #36C3F0 !important;
    a{
      color: #ffffff !important;
    }
  }

  .hintergunddunkelblau{
    background-color: #294b6c !important;
    a{
      color: #ffffff !important;
    }
  }
}

.vc_tta-container{
  .vc_tta-tabs-container{
    .vc_tta-tab{
      a{
        background-color: #294b6c !important;
        color: #ffffff !important;
      }
      &.vc_active a{
        background-color: #0089c1 !important;
      }
    }
  }
  .vc_tta-panels-container{
    .vc_tta-panels{
      background-color: #0089c1 !important;
    }
  }
  .vc_tta-accordion{
    color: #000000 !important;
  }
}

.wpb-js-composer .vc_tta-tabs:not([class*=vc_tta-gap]):not(.vc_tta-o-no-fill).vc_tta-tabs-position-top .vc_tta-tab>a{
  font-size: 25px;
}