/*
Theme Name: TourismusNetzwerk 3.0
Description: TourismusNetzwerk 3.0
Version: 3.0
Author: Shapefruit AG - Christian Lersch
Author URI: http://shapefruit.de
License: GPL
*/

/* AUTOREN */

/* !!!!!!!!!!!!!!!!!!!!!! RPT VERSION !!!!!!!!!!!!!!!!!!!!!!  */

/* -----------------------------------------
   GLOBALE STYLES
----------------------------------------- */

html{
  font-size: 16px;
}

html body{
  font-size: 1rem;
  font-family: $font-family-base;
  overflow-x: hidden;
  color: $graublau;

  &>.container-fluid{
    overflow-x: hidden;
    overflow-y: auto;
  }
}

#tribe-events-pg-template .tribe-events-content .nf-table-display .nf-form-title h3{
  font-size: 25*$px-base;
  font-weight: bold;
}

.text-white, .text-white a{
 color: #ffffff !important;
}

.fullpage, .singlepost{
  a{
    color: $linkblau;
  }
  a.btn{
    color: $secondary;
  }
  a.btn-info{
    background-color: $primary;
    color: $secondary;
  }


  h1{
    font-size: 35*$px-base;
  }

  h2{
    font-size: 30*$px-base;
  }

  h3{
    font-size: 25*$px-base;
    font-weight: bold;
  }
  @media (min-width: 768px) {
    h1{
      font-size: 40*$px-base;
    }

    h2{
      //font-size: 30*$px-base;
    }

    h3{
      //font-size: 25*$px-base;
      margin-bottom: 15px;
    }
  }
  .wp-caption img{
    @include media-breakpoint-down(xs) {
      width: 100% !important;
      height: auto;
    }
  }
  .blogcontentmain{
    padding-right:  20px;
    .post{
      .wp-caption{
          img.size-large{
            width: 100%;
            height: auto;
          }
      }
    }
  }
  .postoptions{
    * {
      text-transform: uppercase !important;
      font-weight: normal !important;
    }
    .borderbox{
      border-top: solid 1px $quaternary;
      @include media-breakpoint-up(lg) {
        border-top: none;
        border-left: solid 1px $quaternary;
        padding-left: 20px;
      }
    }
  }
}

.avatarlogobox{
  margin-left: 30px;

  .avatar{
    img{
      border-radius: 500px;
    }
  }
  .rptlogo{
    margin-top: 50px;
  }
  .pic {
    display: flex;
    flex-direction: column;
    justify-content: center;
      @include media-breakpoint-down(xs) {
        img{
          width: 100px;
          height: auto;
      }
    }
  }
}

#wpadminbar {
  overflow: hidden;
}

/* -----------------------------------------
   POST STYLES
----------------------------------------- */

.headlinebox{
  text-align: center;
}

.headlinebox2{
  text-align: center;
  h2{
    font-size: 22*$px-base;
  }
  svg path{
    fill: $graublau !important;
  }
}

.btn{
  font-weight: 400;
  border-radius: 4px;
  padding: 6px;
}

.btnmehr{
  background-color: $primary;
  border-radius: 15px;
  padding: 3px 8px;
  color: #ffffff !important;
  font-size: 14px;
  font-weight: bold;
  width: 75px;
  display: block;
  text-align: center;
}


.gimmemyarrow{
  margin-left: 5px;
  width: 17px;
  height: 14px;
  stroke: $secondary;
}

.fullpage{
  overflow: hidden;
  .gallery{
    img{
      width: 100%;
      height: auto;
      padding: 10px;
      border: none !important;
    }
  }
}

.titlebalken{
  height: 180px;
  background-color: $primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1{
    color: $secondary;
    font-weight: bold;
    font-size: 35*$px-base;
    @include media-breakpoint-down(xs) {
      font-size: 25*$px-base;
    }
  }
}

blockquote{
  margin: 16px 40px !important;
  font-style: italic !important;
}

.weitereartikel{
  .crp_related{
    .crp_title{
      display: none;
    }
    .card .card-body{
      padding-top: 10px !important;
    }
  }
  .katbox{
    display: none;
  }
}

.page-template-default .fullpage p img{
  max-width: 100%;
  height: auto;
}